import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import * as MovieAction from '../../../actions/MovieAction'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList'
import { CircularProgress, Tooltip, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { Button, Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { styled } from '@mui/material/styles';
import { Avatar, Box, Grid, Paper, tooltipClasses } from '@mui/material';
import videojs from 'video.js';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import VideoJS from '../../../player/player'
import { ROUTES } from '../../../constant/routes'


export default function ComingSoon() {
    const params = useParams()
    const dispatch = useDispatch()
    const { getComingSoonDetails } = bindActionCreators(MovieAction, dispatch)
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [movieData, setMovieData] = useState(null)
    const playerRef = React.useRef(null);
    const history = useHistory()
    useEffect(() => {
        (async () => {
            if (params?.slug) {
                const res = await axios.get("https://api.ipify.org/?format=json");
                let userObj = {
                    user_id: user_id,
                    profile_id: profile_id,
                    ip_address: res.data.ip
                }
                const resData = await getComingSoonDetails(params?.slug, userObj)
                console.log(resData)
                if (resData?.statuscode == 200) {
                    setMovieData(resData?.data)
                }
            }
        })()
    }, [])

    const videoJsOptions = {
        autoplay: true,
        loop: true,
        controls: false,
        responsive: true,
        fluid: true,
        muted: true,
        poster: `${IMAGE_ENDPOINT + movieData?.poster}`,

        plugins: {
            // seekButtons: {
            //     forward: 10,
            //     back: 10
            // }
        },
        volumeControl: false,
        playbackRates: [0.5, 1, 1.5, 2],
        userActions: { hotkeys: true },
        controlBar: {

            qualitySelector: true,
            pictureInPictureToggle: false,

        },

        languages: [{}],
        sources: [
            {
                src: `${movieData?.trailer_url}`,
                // type: 'video/mp4',
                type: `${movieData?.trailer_url?.split('.').pop().toLowerCase() == 'm3u8' ? "application/x-mpegURL" : "video/mp4"}`,
                label: "1080p",
                selected: true,
            }
        ]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.controlBar.addChild('button', {
            controlText: 'Title',
            className: 'vjs-visible-text',
            position: 2,
        });

        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffffff',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const handleTrailer = () => {
        let userObj = {
            movie_name: movieData?.title,
            Trailer: movieData?.trailer_url
        }

        history.push(ROUTES.trailer, { data: userObj })
    }

    return (
        <div>
            {
                movieData ?
                    <div>
                        <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                            <div
                                className='responsive-mute'
                                onClick={(e) => {
                                    playerRef.current.muted(!playerRef.current.muted())
                                    document.getElementById("mute-button").className = !playerRef.current.muted() ? "ri-volume-up-fill" : "ri-volume-mute-fill"
                                    // document.getElementById("mute-button1").className = !playerRef.current.muted() ? "display-none" : "fa fa-times"

                                }}
                                style={{
                                    position: "absolute",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    right: "4vw",
                                    top: "20vh",
                                    zIndex: "10",
                                    fontSize: "20px"
                                }}>
                                <div style={{ background: "#00000047", height: "60px", padding: "18px", borderRadius: "50%", border: "1px solid #ffffff", display: "flex", alignItems: "center" }}>
                                    <i className="ri-volume-mute-fill" id='mute-button' aria-hidden="true"></i>

                                </div>
                            </div>
                            <div
                                id="home-slider"
                                className="slick-bg-1 m-0 p-0">

                                <div className='bg-video'>

                                    {movieData?.trailer_url && <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />}

                                </div>

                                <SwiperSlide className="slick-bg-2 slick-bg-3">
                                    <Container fluid className="position-relative h-100">
                                        <div className="slider-inner h-100">
                                            <Row className="align-items-center iq-ltr-direction h-100 ">
                                                <Col xl="6" lg="12" md="12" >

                                                    <h3 className="trending-text big-title text-uppercase mt-0 mb-2">{movieData?.title}</h3>


                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8" dangerouslySetInnerHTML={{ __html: movieData?.description?.length <= 250 ? movieData?.description : movieData?.description?.substring(0, 250) + "..." }}>
                                                        </p>
                                                    </div>

                                                    <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                                        <p>
                                                            <b>Language:</b> <span>{movieData?.language}</span>
                                                            <br />
                                                            {
                                                                movieData?.genre?.length ? <>
                                                                    <b>Genres:</b> {movieData?.genre?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.genre_title}</span>))}

                                                                </>
                                                                :
                                                                <></>
                                                            }
                                                            <br />
                                                            {/* <b>Starring:</b> {movieData?.Cast?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.cast_name}</span>))} */}
                                                        </p>



                                                    </div>

                                                    <div className="d-flex align-items-start r-mb-23 mt-2" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">

                                                        <Button className="btn btn-hover iq-button">
                                                            {"COMING SOON"}

                                                        </Button>
                                                        <div onClick={handleTrailer} className='ml-2 icon-border' style={{ cursor: "pointer", display: "flex" }}>
                                                            <i className="fa fa-play" aria-hidden="true"></i>
                                                            <span className='pl-1' style={{ fontSize: "11px", whiteSpace: "nowrap" }}>Play Trailer</span>
                                                        </div>
                                                    </div>


                                                </Col>

                                            </Row>
                                        </div>
                                    </Container>
                                </SwiperSlide>

                                {/* </video> */}
                            </div>
                        </section>

                        <div>

                            <div className="tranding-block position-relative mt-3"
                            >
                                <Tab.Container defaultActiveKey="trending-data3" className="trending-custom-tab">
                                    <div className="tab-title-info position-relative iq-ltr-direction">
                                        <Nav as="ul" variant="pills" className="trending-pills nav-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction">

                                            {/* <Nav.Item as="li" className="nav-item">
                                                <Nav.Link href="" eventKey="related-mov">Related</Nav.Link>
                                            </Nav.Item> */}
                                            {/* <Nav.Item as="li" className="nav-item">
                                                <Nav.Link href="" eventKey="trending-data1">Trailer</Nav.Link>
                                            </Nav.Item> */}
                                            <Nav.Item as="li" className="nav-item">
                                                <Nav.Link href="" eventKey="trending-data3">Description</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <Tab.Content className="">
                                        <Tab.Pane eventKey="trending-data1" className="overlay-tab show fade">
                                            {/* <section id="parallex" className="">
                                                <Container>
                                                    <Row className="align-items-center justify-content-center h-100 parallaxt-details">
                                                        <Col lg="6">
                                                       
                                                            <div className="position-relative">
                                                                <div className="img-box">
                                                                    <img src={`${IMAGE_ENDPOINT + movieData?.Data?.movie_poster}`} className="img-fluid w-100 trailer-img" alt="bailey" />
                                                                </div>
                                                             
                                                            </div>
                                                        </Col>
                                                        <Col lg="6" className="r-mb-23">
                                                            <div className="text-left">

                                                                <div className="parallax-ratting d-flex align-items-center mt-3 mb-3">
                                                                    <h6>{movieData?.Data?.movie_name}</h6>
                                                                </div>
                                                                <div className="movie-time d-flex align-items-center mb-3 iq-ltr-direction">
                                                                    <h6 className="text-white"> Trailer duration : {movieData?.Data?.movie_duration}</h6>
                                                                </div>
                                                                <p style={{ fontSize: "13px" }} dangerouslySetInnerHTML={{ __html: movieData?.Data?.movie_description }}></p>
                                                               
                                                                <p>
                                                                    <b>Language:</b> <span>{movieData?.Data?.movie_language}</span><br />

                                                                    <b>Genres:</b> {movieData?.Data?.movie_genre?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.genre_title}</span>))}<br />

                                                                </p>



                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </Container>
                                            </section> */}
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="trending-data2" className="overlay-tab  fade show " id="trending-episode1">
                                            {/* <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                                <Row className='mt-3'>
                                                    <Grid container spacing={2} >
                                                        {
                                                            movieData?.Cast?.map((data, index) => (
                                                                <Grid item xl={2} lg={2} md={3} sm={4} xs={6} key={index} className='grid-center'>

                                                                    <img src={`${IMAGE_ENDPOINT}/${data?.cast_image}`} className="avtar-img" alt={data?.movie_name} />
                                                                    <h6 className='mt-2' style={{ color: "#b9213a", textAlign: "center" }} >{data?.cast_type}</h6>
                                                                    <h6 style={{ color: "white", textAlign: "center" }} className='mt-1'>{data?.cast_name}</h6>

                                                                </Grid>
                                                            ))
                                                        }

                                                    </Grid>
                                                </Row>

                                            </div> */}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="trending-data3" className="overlay-tab fade show">
                                            <Container>
                                                <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">

                                                    <Container>
                                                        {/* <h5>Cast</h5> */}
                                                        {/* <div className='mt-4' style={{ display: "flex", justifyContent: "left", padding: "10px" }}>
                                                            <Container>
                                                                <Grid container spacing={2}>
                                                                    {
                                                                        movieData?.Cast?.map((data, index) => (
                                                                            <Grid lg="1" md="1" sm="2" xs="3" key={index} className='ml-3' size={1}>
                                                                                <img src={IMAGE_ENDPOINT + data?.cast_image} alt={data?.cast_name} style={{ borderRadius: "10px" }} />
                                                                                <p className='mt-2' style={{ textAlign: "center", fontSize: "10px" }}>{data?.cast_name}
                                                                   
                                                                                </p>
                                                                            </Grid>
                                                                        ))
                                                                    }


                                                                </Grid>
                                                            </Container>
                                                        </div> */}
                                                        {/* </Box> */}

                                                        <h5>Audio Languages</h5>
                                                        <ul className='mt-2'>

                                                            <p>{movieData?.language}</p>
                                                        </ul>

                                                        <h5>Description</h5>
                                                        <p style={{ fontSize: "17px", marginTop: "1.2rem" }} dangerouslySetInnerHTML={{ __html: movieData?.description }}></p>
                                                    </Container>
                                                </div>
                                            </Container>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="related-mov" className="overlay-tab fade show">
                                            <div className='main-content'>
                                                <section id="iq-favorites">
                                                    <Container fluid>
                                                        <Row>
                                                            <Col sm="12" className="overflow-hidden">

                                                                <div id="favorites-contens">
                                                                    {/* <div id="prev" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                    <div id="next" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                                                    <Swiper
                                                                        navigation={{
                                                                            prevEl: '#prev',
                                                                            nextEl: '#next'
                                                                        }}
                                                                        breakpoints={{
                                                                            320: { slidesPerView: 2 },
                                                                            450: { slidesPerView: 3 },
                                                                            638: { slidesPerView: 3 },
                                                                            810: { slidesPerView: 4 },
                                                                            // 991: { slidesPerView: 4 },
                                                                            1048: { slidesPerView: 5 },
                                                                            1300: { slidesPerView: 6 },
                                                                            1450: { slidesPerView: 7 },
                                                                            1650: { slidesPerView: 8 },
                                                                            2050: { slidesPerView: 9 },
                                                                            2230: { slidesPerView: 10 },
                                                                            2415: { slidesPerView: 11 },
                                                                        }}
                                                                        loop={false}
                                                                        slidesPerView={6}
                                                                        spaceBetween={10}
                                                                        as="ul"
                                                                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                                                                        {
                                                                            movieData?.Related_Movie?.map((data, index) => (
                                                                                <SwiperSlide as="li" key={index}>
                                                                                    <div className="thumbnail-container">
                                                                                        <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                                                                            <img src={`${IMAGE_ENDPOINT}/${data?.movie_thumbnail}`} className={`thumb-img1`} alt={data?.movie_name} />
                                                                                            {
                                                                                                data?.leaving_soon === "true" && <div className='leaving-soon'>
                                                                                                    Leaving soon
                                                                                                </div>
                                                                                            }
                                                                                        </Link>
                                                                                        <div className='hover-class1'>
                                                                                            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                                                                <PlayCircleOutlineIcon style={{ fontSize: "1.6rem", color: "#ba000b" }} />
                                                                                                <span style={{ fontSize: "1.2rem", color: "white" }}>Watch Now</span>
                                                                                            </div>
                                                                                            <div style={{ color: "white", fontSize: "0.96rem" }}>
                                                                                                {data.movie_name}
                                                                                            </div>
                                                                                            {/* <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                                                                <img src={gujjuCoin} alt='coin' style={{ width: "20px" }} />
                                                                                                {" " + data?.movie_coin} Coins
                                                                                            </div> */}
                                                                                            <span style={{ color: "white", fontSize: "0.96rem" }}>Duration : {data?.movie_duration}
                                                                                            </span>
                                                                                            <span style={{ color: "white", fontSize: "0.96rem" }}> Genre : {data?.movie_genre[0]?.genre_title}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </SwiperSlide>
                                                                            ))
                                                                        }


                                                                    </Swiper>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </section>
                                            </div>
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                            {/* </SwiperSlide>
                            </Swiper> */}
                        </div>


                        {/* Related movie data */}


                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
                        <CircularProgress sx={{ color: "#ba000b" }} />
                    </div>
            }
        </div>
    )
}
